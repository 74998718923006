<template>
  <b-overlay
      :show="shippingType === null"
      rounded="sm"
  >
    <div v-if="shippingType !== null">
      <b-modal
          id="modal-delete"
          cancelTitle="خیر"
          centered

          ok-title="بله"
          @ok="DeleteshippingType"
      >
        <span>آیا از حذف کردن این نوع باربری اطمینان دارید ؟ </span>
      </b-modal>
      <b-modal
          id="modal-edit"
          centered
          ok-only
          ok-title="بروزرسانی نوع باربری"
          @ok.preventDefault()="UpdateshippingType"
      >
        <b-row v-if="selectedShippingType">
          <b-col cols="12">
            <b-form-group>
              <label for="email">نام نوع باربری</label>
              <b-form-input
                  v-model="selectedShippingType.name"
                  placeholder="وانت"
                  type="text"
              />
            </b-form-group>
          </b-col>

        </b-row>


      </b-modal>
      <b-modal
          id="modal-Add"
          centered
          ok-only
          ok-title="افزودن نوع باربری"

          @ok.preventDefault()="CreateshippingType"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <label for="email">نام نوع باربری</label>
              <b-form-input
                  v-model="shippingTypeName"
                  placeholder="وانت"
                  type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
      <!-- Table Container Card -->
      <b-card
          class="mb-0"
          no-body
      >
        <b-col class="my-2" lg="12" md="12">
          <b-row>
            <b-col class="my-2" lg="2" md="12">
              <b-button
                  class="w-100"
                  v-b-modal.modal-Add
                  variant="success"

              >
                افزودن نوع باربری جدید
              </b-button>
            </b-col>
            <b-col class="my-2 ml-auto" lg="2" md="12">
              <clear-sorts-btn @clear="sortBy= '' "></clear-sorts-btn>
            </b-col>
          </b-row>
        </b-col>


        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="shippingType"
            bordered
            :sort-by.sync="sortBy"
            class="position-relative"
            empty-text="موردی موجود نیست!"
            primary-key="id"
            responsive
            show-empty
            striped
        >

          <!-- Column: delete -->

          <template #cell(operation)="data">
            <div class="d-flex align-items-center justify-content-between">

              <div v-b-modal.modal-delete
                   class="cursor-pointer d-flex flex-row"
                   @click="setSelectedShippingType(data.item)"
              >
                <feather-icon class="text-danger" icon="TrashIcon" size="20"/>
              </div>
              <div v-b-modal.modal-edit
                   class="cursor-pointer d-flex flex-row"
                   @click="setSelectedShippingType(data.item)"
              >
                <feather-icon class="text-primary" icon="EditIcon" size="20"/>
              </div>
            </div>

          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-end "
                cols="12"
                sm="12"
            >

              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon"
                                size="18"
                                style="transform: rotate(180deg)"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>


  </b-overlay>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormFile,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal, BFormGroup, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {CreateShippingType, UpdateShippingType, DeleteShippingType, GetAllShippingType} from "@/libs/Api/shipping";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import helper from "@/libs/Helper";
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";

export default {
  title: 'لیست نوع باربری ها  - پنل ادمین انزا مد',
  name: 'shippingType',
  data() {
    return {
      shippingType: null,
      totalCount: null,
      perPageOptions: [10, 20, 30, 40, 50],
      sortBy: '',
      myTableColumns: [
        {
          key: 'shippingTypeId',
          label: 'شناسه',
          sortable: true
        },
        {
          key: 'name',
          label: 'کد',
          sortable: true
        },
        {
          key: 'operation',
          label: 'عملیات'
        },

        // { key: 'parentId'},
      ],
      shippingTypeName: '',
      currentPage: 1,
      perPage: 10,
      selectedShippingType: null,

    }
  },
  async created() {
    await this.getAllShippingType()
  },
  components: {
    ClearSortsBtn,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
  watch: {

    currentPage: function () {
      this.getAllShippingType()
    }
  },
  methods: {
    async UpdateshippingType() {
      let _this = this
      let updateShippingType = new UpdateShippingType(_this)

      updateShippingType.setData(_this.selectedShippingType)
      await updateShippingType.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `نوع باربری بروزرسانی شد`,
          },
        })
        _this.selectedShippingType = null

        _this.getAllShippingType();
      }, function (error) {
        console.log(error)
      })
    },
    async CreateshippingType() {
      let _this = this

      if (_this.shippingTypeName !== '') {

        let createShippingType = new CreateShippingType(_this)
        let data = {
          name: _this.shippingTypeName,
          createDate: new Date(Date.now())
        }
        createShippingType.setData(data)
        await createShippingType.fetch(function (content) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `نوع باربری ساخته  شد`,
            },
          })
          _this.getAllShippingType();
          _this.shippingTypeName = ''

        }, function (error) {
          console.log(error)
        })
      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: `لطفا نام را وارد  کنید`,
          },
        })
      }


    },
    async DeleteshippingType() {
      let _this = this
      let deleteShippingType = new DeleteShippingType(_this)
      deleteShippingType.setParams({id: this.selectedShippingType.shippingTypeId})
      await deleteShippingType.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `نوع باربری  حذف شد.`,
          },
        })
        _this.getAllShippingType();
      }, function (error) {
        console.log(error)
      })
    },
    setSelectedShippingType(item) {
      this.selectedShippingType = JSON.parse(JSON.stringify(item))
    },
    async getAllShippingType() {
      let _this = this
      let getAllShippingType = new GetAllShippingType(_this)
      let data = {
        pageNumber: _this.currentPage,
        count: 10
      }
      getAllShippingType.setParams(data)
      await getAllShippingType.fetch(function (content) {
        _this.shippingType = content.shippingTypes
        _this.totalCount = content.shippingTypesCount
      }, function (error) {
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>

</style>
